// import { request } from '@/network/request'
import { request } from "./request"

export function getAction(url,parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}
export function postAction(url,parameter) {
  return request({
    url: url,
    method:'post' ,
    data: parameter
  })
}
export function getCategory(){
  return request({
    url:'/category'
  })
}

export function getSubcategory(maitKey){
  return request({
    url:'/subcategory',
    params:{
      maitKey
    }
  })
}

export function getCategoryDetail(miniWallkey, type){
  return request({
    url:'/subcategory/detail',
    params:{
      miniWallkey,
      type
    }
  })
}