<template>
    <div id="wxAuth">
    </div>
</template>
  
<script>

export default {
    name: "wxAuth",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            //微信授权
            vm.toWxAuth();

        });
    },

    data() {
        return {
            //微信授权参数
            authParam: {
                redirectUri: ""//重定向到的url
            },

        }
    },
    methods: {

        //跳转进行微信授权
        toWxAuth() {
            // //获取sku,如果非空,则在跳转链接加上此参数
            let sku = this.$route.query.sku
            let url = this.$route.query.url
            if (!url) {
                url = "https://www.yzb10086.com/BuyGoods?goodsGroupId=1698974521556033537"
            }

            // let openId = wxUtis.getOpenId()
            let wxJsapiAppId = !this.$route.query.wxJsapiAppId ? 'wx2ee771067a032dc7' : this.$route.query.wxJsapiAppId
            let authUrl;
            let ljf = "&"
            if (url.indexOf("?") == -1) {
                ljf = "?"
            }
            if (!sku) {
                authUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wxJsapiAppId + "&redirect_uri=" + encodeURIComponent(url + ljf + "wxJsapiAppId=" + wxJsapiAppId) +
                    "&response_type=code&scope=snsapi_base#wechat_redirect"
            } else {
                authUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wxJsapiAppId + "&redirect_uri=" + encodeURIComponent(url + ljf + "wxJsapiAppId=" + wxJsapiAppId +
                    "&sku=" + sku) + "&response_type=code&scope=snsapi_base#wechat_redirect"
            }
            location.href = authUrl

        }
    }
}
</script>
  
<style scoped></style>
  